var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"dense":"","align":"center"}},[(_vm.userAccess.canUpdate)?_c('v-col',{attrs:{"cols":"auto"}},[(_vm.dataTypeProp === 'customer'
        || _vm.dataTypeProp === 'transporter'
        || _vm.dataTypeProp === 'selection_info'
      )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","elevation":"1","small":"","loading":_vm.uploadingCsv}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$_strings.selectioninfo.button.UPLOAD_CSV)+" ")])]}}],null,false,2908508546)},[_c('v-list',_vm._l((_vm.dataTypeOptions),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.handleUploadCsvDropdownClick(item.dataType)}}},[_c('v-list-item-title',{attrs:{"for":"csv-upload"},domProps:{"textContent":_vm._s(item.label)}})],1)}),1)],1):_c('v-btn',{attrs:{"color":"primary","dark":"","elevation":"1","small":"","loading":_vm.uploadingCsv},on:{"click":function($event){return _vm.handleUploadCsvDropdownClick(_vm.dataTypeProp)}}},[_vm._v(" "+_vm._s(_vm.$_strings.selectioninfo.button.UPLOAD_CSV)+" ")]),_c('input',{ref:"fileInput",attrs:{"id":"csv-upload","disabled":_vm.uploadingCsv,"type":"file","accept":".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"},on:{"change":_vm.uploadCsv}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.dataTypeProp === 'customer'
        || _vm.dataTypeProp === 'transporter'
        || _vm.dataTypeProp === 'selection_info'
      )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","elevation":"1","small":"","loading":_vm.downloadingCsv}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$_strings.selectioninfo.button.TEMPLATE_CSV)+" ")])]}}],null,false,1778772518)},[_c('v-list',_vm._l((_vm.dataTypeOptions),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.handleDownloadCsvDropdownClick(item.dataType)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.label)}})],1)}),1)],1):_c('v-btn',{attrs:{"color":"primary","dark":"","elevation":"1","small":"","loading":_vm.downloadingCsv},on:{"click":function($event){return _vm.downloadCsvTemplate(_vm.dataTypeProp)}}},[_vm._v(" "+_vm._s(_vm.$_strings.selectioninfo.button.TEMPLATE_CSV)+" ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('UploadedCsvStatusDialog',{attrs:{"importType":_vm.importTypeProp,"dataType":_vm.dataTypeProp}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }